import React from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { getIframeSrcTag, getIframeCode } from "./iframeEmbedGenerator";

const rowStyle = {
  whiteSpace: "nowrap",
  display: "table-row",
};

const itemStyle = {
  display: "table-cell",
};

class ConfigureIframe extends React.Component {
  constructor() {
    super();
    this.state = {
      src: null,
    };
    this.handleSubmit = event => {
      event.preventDefault();
      const data = new FormData(event.target);
      const source = getIframeSrcTag(data);
      this.setState({ src: source });
    };
  }

  render() {
    const src = this.state.src;
    const iframeCode = getIframeCode(src);
    const iframeExplainer = src
      ? iframeCode
      : `Your embed code will appear here after entering details above`;

    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <div style={rowStyle}>
            <label htmlFor="apikey" style={itemStyle}>
              Your API key ('apikey')
            </label>
            <input id="apikey" name="apikey" type="text" style={itemStyle} />
          </div>
          <div style={rowStyle}>
            <label htmlFor="text" style={itemStyle}>
              Topic name ('text'), e.g. Drones
            </label>
            <input id="text" name="text" type="text" style={itemStyle} />
          </div>
          <div style={rowStyle}>
            <label htmlFor="link" style={itemStyle}>
              Link URL ('link'), e.g. https://site.com/drones
            </label>
            <input id="link" name="link" type="text" style={itemStyle} />
          </div>
          <div style={rowStyle}>
            <label htmlFor="topicid" style={itemStyle}>
              Topic ID ('topicid'), e.g. a1Gb0000002ZKplEAG
            </label>
            <input id="topicid" name="topicid" type="text" style={itemStyle} />
          </div>
          <button>Generate embed code</button>
          <SyntaxHighlighter language="html" wrapLines="true">
            {iframeExplainer}
          </SyntaxHighlighter>
        </form>
        <CopyToClipboard text={iframeExplainer}>
          <button>Copy to clipboard</button>
        </CopyToClipboard>
      </div>
    );
  }
}

export default ConfigureIframe;
