import React from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { getInsiwIframeSrcTag, getIframeCode } from "./iframeEmbedGenerator";

const rowStyle = {
  whiteSpace: "nowrap",
  display: "table-row",
};

const itemStyle = {
  display: "table-cell",
};


export function getInsiwIframeCode(src) {
  let height;
  if (src && src.includes('mode=narrow')) {
    height = 300
  } else if (src && src.includes('mode=wide')) {
    height = 1200
  } else {
    height = 600;
  }
  return `<iframe
    src="${src}"
    style="height: ${height}px; width: 100%; border: none"
    name="intelligenceWidgetIframe"
    >
  </iframe>
`;
}

class ConfigureBasicInsiwIframe extends React.Component {
  constructor() {
    super();
    this.state = {
      src: null,
    };
    this.handleSubmit = event => {
      event.preventDefault();
      const data = new FormData(event.target);
      const source = getInsiwIframeSrcTag(data);
      this.setState({ src: source });
    };
  }

  render() {
    const src = this.state.src;
    const iframeCode = getInsiwIframeCode(src);
    const iframeExplainer = src
      ? iframeCode
      : `Your embed code will appear here after entering details above`;

    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <div style={rowStyle}>
            <label htmlFor="apikey" style={itemStyle}>
              Your API key ('apikey') - required
            </label>
            <input id="apikey" name="apikey" type="text" style={itemStyle} />
          </div>
          <div style={rowStyle}>
            <label htmlFor="topicid" style={itemStyle}>
              Topic id, e.g. a1G0X000006O06IUAS - required
              <br/>
              You topic id is found in the url of the topic on Strategic Intelligence
              <br />
              e.g. <a href={'https://intelligence.weforum.org/topics/a1G0X000006O06IUAS'}>https://intelligence.weforum.org/topics/a1G0X000006O06IUAS</a>
            </label>
            <input id="topicid" name="topicid" type="text" style={itemStyle} />
          </div>

          <button>Generate embed code</button>
          <SyntaxHighlighter language="html" wrapLines="true">
            {iframeExplainer}
          </SyntaxHighlighter>
        </form>
        <CopyToClipboard text={iframeExplainer}>
          <button>Copy to clipboard</button>
        </CopyToClipboard>
      </div>
    );
  }
}

class ConfigureAdvancedInsiwIframe extends React.Component {
  constructor() {
    super();
    this.state = {
      src: null,
    };
    this.handleSubmit = event => {
      event.preventDefault();
      const data = new FormData(event.target);
      const source = getInsiwIframeSrcTag(data);
      this.setState({ src: source });
    };
  }

  render() {
    const src = this.state.src;
    const iframeCode = getInsiwIframeCode(src);
    const iframeExplainer = src
      ? iframeCode
      : `Your embed code will appear here after entering details above`;

    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <div style={rowStyle}>
            <label htmlFor="apikey" style={itemStyle}>
              Your API key ('apikey') - required
            </label>
            <input id="apikey" name="apikey" type="text" style={itemStyle} />
          </div>
          <div style={rowStyle}>
            <label htmlFor="topicid" style={itemStyle}>
              Topic id, e.g. a1G0X000006O06IUAS - required
              <br/>
              You topic id is found in the url of the topic on Strategic Intelligence
              <br />
              e.g. <a href={'https://intelligence.weforum.org/topics/a1G0X000006O06IUAS'}>https://intelligence.weforum.org/topics/a1G0X000006O06IUAS</a>
            </label>
            <input id="topicid" name="topicid" type="text" style={itemStyle} />
          </div>
          <div style={rowStyle}>
            <label htmlFor="labeltext" style={itemStyle}>
              Label text - optional
            </label>
            <input id="labeltext" name="labeltext" type="text" style={itemStyle} />
          </div>
          <div style={rowStyle}>
            <label htmlFor="headertext" style={itemStyle}>
              Header text - optional
            </label>
            <input id="headertext" name="headertext" type="text" style={itemStyle} />
          </div>
          <div style={rowStyle}>
            <label htmlFor="buttontext" style={itemStyle}>
              Button text - optional
            </label>
            <input id="buttontext" name="buttontext" type="text" style={itemStyle} />
          </div>
          <div style={rowStyle}>
            <label htmlFor="positionofwordstohighlight" style={itemStyle}>
              Words to highlight in the header, starting at 0. E.g. [0,2,3] - optional
            </label>
            <input id="positionofwordstohighlight" name="positionofwordstohighlight" type="text" style={itemStyle} />
          </div>
          <div style={rowStyle}>
            <label htmlFor="initialindex" style={itemStyle}>
              Initial index - optional
            </label>
            <input id="initialindex" name="initialindex" type="text" style={itemStyle} />
          </div>
          <div style={rowStyle}>
            <label htmlFor="showexplorenavigationbuttons" style={itemStyle}>
              Show explore navigation buttons - optional
            </label>
            <input id="showexplorenavigationbuttons" name="showexplorenavigationbuttons" type="text" style={itemStyle} />
          </div>
          <div style={rowStyle}>
            <label htmlFor="language" style={itemStyle}>
              Language - optional
              <select name="language" id="language">
                <option value={'en'}>English</option>
                <option value={'pt'}>Portuguese</option>
                <option value={'zh'}>Chinese</option>
                <option value={'es'}>Spanish</option>
                <option value={'ja'}>Japanese</option>
                <option value={'ar'}>Arabic</option>
                <option value={'fr'}>French</option>
                <option value={'de'}>German</option>
                <option value={'hi'}>Hindi</option>
                <option value={'ko'}>Korean</option>
              </select>
            </label>
          </div>
          <div style={rowStyle}>
            <label htmlFor="mode" style={itemStyle}>
                Mode - optional
            </label>
            <input id="mode-narrow" name="mode" type="radio" style={itemStyle} value={'narrow'} />
            <label htmlFor="mode-narrow">Narrow</label>
            <input id="mode-normal" name="mode" type="radio" style={itemStyle} value={'normal'}/>
            <label htmlFor="mode-normal">Normal</label>
            <input id="mode-wide" name="mode" type="radio" style={itemStyle} value={'wide'} />
            <label htmlFor="mode-wide">Wide</label>
          </div>
          <div style={rowStyle}>
            <label htmlFor="fontfamilysrc" style={itemStyle}>
              Font family source - optional
            </label>
            <input id="fontfamilysrc" name="fontfamilysrc" type="text" style={itemStyle} />
          </div>
          <div style={rowStyle}>
            <label htmlFor="wrappercolor" style={itemStyle}>
              Wrapper Colour - optional
            </label>
            <input id="wrappercolor" name="wrappercolor" type="color" style={itemStyle} />
          </div>
          <div style={rowStyle}>
            <label htmlFor="primarycolor" style={itemStyle}>
              Primary Colour - optional
            </label>
            <input defaultValue={"#1E1E1E"} id="primarycolor" name="primarycolor" type="color" style={itemStyle} />
          </div>
          <div style={rowStyle}>
            <label htmlFor="secondarycolor" style={itemStyle}>
              Secondary Colour - optional
            </label>
            <input defaultValue={"#f2db72"} id="secondarycolor" name="secondarycolor" type="color" style={itemStyle} />
          </div>
          <div style={rowStyle}>
            <label htmlFor="tertiarycolor" style={itemStyle}>
              Tertiary Colour - optional
            </label>
            <input defaultValue={"#292929"} id="tertiarycolor" name="tertiarycolor" type="color" style={itemStyle} />
          </div>
          <div style={rowStyle}>
            <label htmlFor="headercolor" style={itemStyle}>
              Header Colour - optional
            </label>
            <input defaultValue={"#ffffff"} id="headercolor" name="headercolor" type="color" style={itemStyle} />
          </div>
          <div style={rowStyle}>
            <label htmlFor="subtitlecolor" style={itemStyle}>
              Subtitle Colour - optional
            </label>
            <input defaultValue={"#d3d3d3"} id="subtitlecolor" name="subtitlecolor" type="color" style={itemStyle} />
          </div>
          <button>Generate embed code</button>
          <SyntaxHighlighter language="html" wrapLines="true">
            {iframeExplainer}
          </SyntaxHighlighter>
        </form>
        <CopyToClipboard text={iframeExplainer}>
          <button>Copy to clipboard</button>
        </CopyToClipboard>
      </div>
    );
  }
}

export { ConfigureAdvancedInsiwIframe, ConfigureBasicInsiwIframe };
