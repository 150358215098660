
import React from 'react';
import ReactDOM from 'react-dom';
import RedBox from 'redbox-react';
import App from '/home/runner/work/intelligence-widget/intelligence-widget/node_modules/component-docs/dist/templates/App.js';
import data from './app.data';
import '/home/runner/work/intelligence-widget/intelligence-widget/node_modules/component-docs/dist/styles/reset.css';
import '/home/runner/work/intelligence-widget/intelligence-widget/node_modules/component-docs/dist/styles/globals.css';

import '/home/runner/work/intelligence-widget/intelligence-widget/docs/styles/docs.css';

const root = document.getElementById('root');
const render = () => {
  try {
    ReactDOM.hydrate(
      <App
        name={window.__INITIAL_PATH__}
        data={data}
        github={undefined}
        logo={"/images/banner.png"}
        title={undefined}
      />,
      root
    );
  } catch (e) {
    ReactDOM.render(
      <RedBox error={e} />,
      root
    );
  }
};

if (module.hot) {
  module.hot.accept(() => {
    render();
  });
}

render();
