import React from "react";
import SimpleReactValidator from "simple-react-validator";

const slackWebHook =
  "https://hooks.slack.com/services/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";

const domainsRegex = /^((?!:\/\/)([a-zA-Z0-9-_*]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?[,]?[ ]?)*$/g;

const rowStyle = {
  whiteSpace: "nowrap",
  display: "table-row",
};

const itemStyle = {
  display: "table-cell",
};

class RequestApiKey extends React.Component {
  constructor() {
    super();
    this.state = {
      confirm: null,
      domain: "",
      email: "",
    };
    this.validator = new SimpleReactValidator({
      validators: {
        domain: {
          message: "Must be a valid domain or comma-separated domains.",
          rule: (val, params, validator) => {
            return (
              validator.helpers.testRegex(val, domainsRegex) &&
              params.indexOf(val) === -1
            );
          },
        },
      },
    });
  }

  sendApplication = (domain, email) => {
    const payload = JSON.stringify({
      text: `We received a key request for the widget for the domain "${domain}" from ${email}`,
      blocks: [
        {
          type: "section",
          text: {
            type: "mrkdwn",
            text: "We received a key request for the widget.",
          },
        },
        {
          type: "section",
          text: {
            type: "mrkdwn",
            text: `:link: *domain:* ${domain}\n\n:email: *email:* ${email}`,
          },
        },
      ],
    });
    this.setState({ isLoading: true });
    fetch(slackWebHook, {
      method: "POST",
      body: payload,
    })
      .then(response => {
        if (!response.ok) {
          throw new Error();
        }
        this.setState({
          isLoading: false,
          confirm: "Thanks, we are considering your request.",
        });
      })
      .catch(() => {
        this.setState({
          isLoading: false,
          confirm: "Sorry, something went wrong.",
        });
      });
  };

  handleSubmit = event => {
    event.preventDefault();
    if (this.validator.allValid()) {
      const { domain, email } = this.state;
      this.sendApplication(domain, email);
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  setField = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    return (
      <div>
        <div style={rowStyle}>
          <label style={itemStyle}>Your domain:</label>
          <input
            id="domain"
            name="domain"
            style={itemStyle}
            value={this.state.domain}
            onChange={this.setField}
          />
          {this.validator.message(
            "domain",
            this.state.domain,
            "required|domain",
          )}
        </div>
        <div style={rowStyle}>
          <label style={itemStyle}>Your email address:</label>
          <input
            id="email"
            name="email"
            style={itemStyle}
            value={this.state.email}
            onChange={this.setField}
          />
          {this.validator.message("email", this.state.email, "required|email")}
        </div>
        <div style={rowStyle}>
          <button onClick={this.handleSubmit}>Apply</button>
          <label id="confirm" style={itemStyle}>
            {this.state.confirm}
          </label>
        </div>
      </div>
    );
  }
}

export default RequestApiKey;
