export function getIframeSrcTag(data) {
  const params = ["text", "apikey", "link", "topicid"];
  var source =
    "https://cdn.intelligence.weforum.org/iframe.html?openinnewwindow=true";

  return params.reduce(
    (url, param) =>
      data.get(param) ? `${url}&${param}=${data.get(param)}` : url,
    source,
  );
}

export function getInsiwIframeSrcTag(data) {
  const params = [
    "apikey",
    "topicid",
    "labeltext",
    "headertext",
    "buttontext",
    "iscollapsable",
    "isinitialisedcollapsed",
    "positionofwordstohighlight",
    "wrappercolor",
    "primarycolor",
    "secondarycolor",
    "tertiarycolor",
    "initialindex",
    "headercolor",
    "subtitlecolor",
    "showexplorenavigationbuttons",
    "language",
    "mode",
    "fontfamilysrc"
  ]

  const source = "https://cdn.intelligence.weforum.org/insiw/v1/iframe.html?isinitialisedcollapsed=false&iscollapsable=false";
  return params.reduce(
    (url, param) =>
      data.get(param) ? `${url}&${param}=${data.get(param).replace('#', '%23')}` : url,
    source,
  );
}

export function getIframeCode(src) {
  return `<iframe
  src="${src}"
  style="border:0px solid white;"
  name="intelligenceWidgetIframe"
  scrolling="no"
  frameborder="0px"
  marginheight="0px"
  marginwidth="0px"
  height="300px"
  width="300px"
  >
</iframe>`;
}
